import moment from 'moment-timezone';
import { collect } from 'collect.js';

class HelperMethods {
  formatDate(date, format) {
    try {
      if (!date) {
        return '';
      }

      if (format) {
        return moment(date).format(format);
      }

      return moment(date).format('DD.MM.YYYY');
    } catch (error) {
      return '';
    }
  }

  formatDateTime(date) {
    return this.formatDate(date, 'DD.MM.YYYY HH:mm');
  }

  /**
   * returns the relative time from now. E.g. 3 weeks ago
   */
  formatRelativeTime(date, withoutSuffix = true) {
    try {
      if (!date) {
        return '';
      }

      return moment(date).fromNow(withoutSuffix);
    } catch (error) {
      return '';
    }
  }

  formatCurrency(amount, currency = 'EUR', locale = null) {
    try {
      return new Intl.NumberFormat(locale ?? this.guessLocaleFromCurrency(currency), {
        style: 'currency',
        currency,
      }).format(amount);
    } catch (error) {
      return amount;
    }
  }

  guessLocaleFromCurrency(currency) {
    return {
      EUR: 'de-DE',
      USD: 'en-US',
      GBP: 'en-UK',
    }[currency] ?? 'de-DE';
  }

  formatPercentage(percentage) {
    return `${percentage}%`;
  }

  percentagePart(a, b, fractionDigits = 2) {
    if (a === 0 || b === 0) {
      return 0;
    }

    const result = (a / b) * 100;
    return parseFloat(result.toFixed(fractionDigits));
  }

  randomString(length = 6) {
    const string = btoa(Math.random());

    return string.slice(string.length - 2 - length, string.length - 2).toUpperCase();
  }

  getFilenameFromUrl(url) {
    try {
      return url.split('/').lastItem;
    } catch {
      return '';
    }
  }

  parseFloat(value) {
    if (typeof value !== 'string') {
      return value;
    }

    return parseFloat(value.replace(',', '.'));
  }

  empty(value) {
    return !value
      || value === undefined
      || (Array.isArray(value) && value.length === 0);
  }

  min(...values) {
    return collect(values)
      .filter((item) => !!item)
      .min();
  }
}

export default new HelperMethods();
