import FinishStep from './FinishStep';
import UploadStep from './UploadStep';
import ValidationStep from './ValidationStep';

export default {
  title: 'Followers',
  steps: {
    upload: 'Hochladen',
    validate: 'Bestätigen',
    finish: 'Erledigt',
  },
  labels: {
    import: 'Importieren',
  },

  FinishStep,
  UploadStep,
  ValidationStep,
};
