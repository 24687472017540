import FinishStep from './FinishStep';
import UploadStep from './UploadStep';
import ValidationStep from './ValidationStep';

export default {
  title: 'Followers',
  steps: {
    upload: 'Upload',
    validate: 'Confirm',
    finish: 'Done',
  },
  labels: {
    import: 'Import',
  },

  FinishStep,
  UploadStep,
  ValidationStep,
};
