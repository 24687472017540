import Import from './import';
import FollowerDetails from './FollowerDetails';

const followers = {
  title: 'Follower',
  fields: {
    name: 'Name',
    subscribed: 'Abonniert',
    followed: 'Gefolgt',
    phone: 'Telefon',
    recent_order: 'Letzte Bestellung',
    total_orders: 'Total Bestellungen',
    birthday: 'Geburtstag',
    google_review: 'Google Review',
    marketing_subscription_source: 'Abonniert von',
  },
  empty_list: 'Noch keine Kunden',
  yes: 'Ja',
  no: 'Nein',
  buttons: {
    import: 'Importieren',
    export: 'Exportieren',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
  labels: {
    followed_since_date: 'Seit {{date}}',
  },
};

export default {
  ...followers,
  Import,
  FollowerDetails,
};
