export default {
  form: {
    first_name: {
      label: 'Vornamen',
    },
    last_name: {
      label: 'Nachname',
    },
    email: {
      label: 'Email',
    },
    phone_number: {
      label: 'Rufnummer',
    },
    birthday: {
      label: 'Geburtstag',
    },
    city: {
      label: 'Stadt',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    success: 'Profil erfolgreich aktualisiert',
    error: 'Profil kann nicht aktualisiert werden',
  },
};
