export default {
  title: 'Click or drag file to this area to upload',
  description: 'Currently supporting .csv, .xls, .xlsx formats',
  buttons: {
    download_template: 'Download import template',
  },
  toasts: {
    error: 'Failed to upload file',
  },
};
