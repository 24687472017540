export default {
  form: {
    first_name: {
      label: 'First name',
    },
    last_name: {
      label: 'Last name',
    },
    email: {
      label: 'Email',
    },
    phone_number: {
      label: 'Phone number',
    },
    birthday: {
      label: 'Birthday',
    },
    city: {
      label: 'City',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    success: 'Profile updated successfully',
    error: 'Failed to update profile',
  },
};
