import { ValidationStatus } from '../../../../../../../views/marketing/followers/import';

export default {
  status: {
    [ValidationStatus.Valid]: {
      title: 'Your import is almost ready!',
      description: 'Click on proceed button to complete the import',
    },
    [ValidationStatus.HasInvalidContent]: {
      title: 'Some data in your file is missing or have wrong format',
      description: 'Please fix the errors below and re-upload the file or proceed to ignore invalid rows/cells',
    },
    [ValidationStatus.Invalid]: {
      title: 'Uploaded file can not be processed',
      description: 'Please fix the errors below and try again',
    },
  },
  sections: {
    heading_validation: {
      title: 'Column names/order mismatch detected:',
      rules: {
        Exact: 'Column #{{column}} must be presented and exactly called <strong>{{expected}}</strong>',
      },
    },
    content_validation: {
      title: 'Following errors detected:',
      labels: {
        row: 'Row <strong>#{{row}}:</strong>',
        imported_rows_one: '{{count}} row will be imported',
        imported_rows_other: '{{count}} rows will be imported',
        ignored_rows_one: '{{count}} row will be ignored',
        ignored_rows_other: '{{count}} rows will be ignored',
      },
      fields: {
        email: 'Email',
        phone_number: 'Phone number',
        first_name: 'First name',
        last_name: 'Last name',
        birthday: 'Birthday',
      },
      rules: {
        Required: '{{field}} field is required. Row will be ignored',
        Email: '{{field}} field is expected to be a valid email address. Row will be ignored',
        Phone: '{{field}} field is expected to be a phone number in the international format. Field will be ignored',
        Date: '{{field}} field is expected to be a valid date. Field will be ignored',
      },
    },
  },
  buttons: {
    back_to_upload: 'Back to upload',
    proceed: 'Proceed',
  },
};
