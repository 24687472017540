export default {
  title: 'Klicken oder ziehen Sie die Datei zum Hochladen in diesen Bereich',
  description: 'Unterstützt derzeit die Formate .csv, .xls, .xlsx',
  buttons: {
    download_template: 'Importvorlage herunterladen',
  },
  toasts: {
    error: 'Datei kann nicht hochgeladen werden',
  },
};
