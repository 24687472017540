import FollowerDetails from './FollowerDetails';
import Import from './import';

const followers = {
  title: 'Followers',
  fields: {
    name: 'Name',
    subscribed: 'Subscribed',
    followed: 'Followed',
    phone: 'Phone',
    recent_order: 'Recent Order',
    total_orders: 'Total Orders',
    birthday: 'Birthday',
    google_review: 'Google Review',
    marketing_subscription_source: 'Subscribed from',
  },
  empty_list: 'No customers yet',
  yes: 'Yes',
  no: 'No',
  buttons: {
    import: 'Import',
    export: 'Export',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
  labels: {
    followed_since_date: 'Since {{date}}',
  },
};

export default {
  ...followers,
  FollowerDetails,
  Import,
};
